export const ExternalLink = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 48 48'
    className='icons__externallink'
    {...props}
  >
    <path d="M40.96 4.98a2 2 0 0 0-.22.02H28a2 2 0 1 0 0 4h8.172L22.586 22.586a2 2 0 1 0 2.828 2.828L39 11.828V20a2 2 0 1 0 4 0V7.246a2 2 0 0 0-2.04-2.266zM12.5 8C8.383 8 5 11.383 5 15.5v20c0 4.117 3.383 7.5 7.5 7.5h20c4.117 0 7.5-3.383 7.5-7.5V26a2 2 0 1 0-4 0v9.5c0 1.947-1.553 3.5-3.5 3.5h-20A3.483 3.483 0 0 1 9 35.5v-20c0-1.947 1.553-3.5 3.5-3.5H22a2 2 0 1 0 0-4h-9.5z" />
  </svg>
)

export const Github = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 48 48'
    className='icons__github'
    {...props}
  >
    <path d="M44 24c0 8.96-5.88 16.54-14 19.08V38c0-1.71-.72-3.24-1.86-4.34 5.24-.95 7.86-4 7.86-9.66 0-2.45-.5-4.39-1.48-5.9.44-1.71.7-4.14-.52-6.1-2.36 0-4.01 1.39-4.98 2.53C27.57 14.18 25.9 14 24 14c-1.8 0-3.46.2-4.94.61C18.1 13.46 16.42 12 14 12c-1.42 2.28-.84 4.74-.3 6.12C12.62 19.63 12 21.57 12 24c0 5.66 2.62 8.71 7.86 9.66-.67.65-1.19 1.44-1.51 2.34H16c-1.44 0-2-.64-2.77-1.68s-1.6-1.74-2.59-2.03c-.53-.06-.89.37-.42.75 1.57 1.13 1.68 2.98 2.31 4.19.57 1.09 1.75 1.77 3.08 1.77H18v4.08C9.88 40.54 4 32.96 4 24 4 12.95 12.95 4 24 4s20 8.95 20 20z" />
  </svg>
)

export const LinkedIn = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox='0 0 48 48'
    className='icons__linkedin'
    {...props}
  >
    <path d="M36.5 6h-25A5.507 5.507 0 0 0 6 11.5v25c0 3.032 2.468 5.5 5.5 5.5h25c3.032 0 5.5-2.468 5.5-5.5v-25C42 8.468 39.532 6 36.5 6zM18 34a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V21a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v13zm-2.5-16a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zM35 34a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1v-7.5c0-1.379-1.121-2.5-2.5-2.5S25 25.121 25 26.5V34a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V21a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v.541A5.965 5.965 0 0 1 29 20c3.309 0 6 2.691 6 6v8z" />
  </svg>
)

export const Scroll = (props) => (
  <svg
    width="700pt"
    height="700pt"
    viewBox="0 0 700 700"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path d="m136.94 327.03 176.64 176.64c8.148 8.148 16.188 16.461 24.445 24.445l.328.328c6.29 6.29 16.898 6.29 23.188 0l20.125-20.125 48.125-48.125 58.133-58.133 50.258-50.258c8.149-8.148 16.461-16.188 24.445-24.445l.328-.328c6.29-6.29 6.29-16.898 0-23.188l-37.953-37.953-5.36-5.36c-6.288-6.288-16.897-6.288-23.187 0l-15.75 15.75-37.68 37.68-45.5 45.5-39.375 39.376c-6.399 6.398-12.961 12.578-19.195 19.195l-.274.273h23.188l-15.75-15.75a12230.993 12230.993 0 0 0-37.734-37.898l-45.555-45.555-39.539-39.539c-6.398-6.398-12.633-12.906-19.14-19.141l-.274-.273c-6.29-6.29-16.898-6.29-23.188 0l-38.281 38.28-5.414 5.415c-6.125 6.015-6.617 17.172-.055 23.188s16.734 6.508 23.188 0l43.695-43.695h-23.188l15.75 15.75 37.844 37.844 45.555 45.555 39.54 39.539c6.398 6.398 12.632 12.906 19.14 19.14l.274.274c6.289 6.29 16.898 6.29 23.188 0l15.75-15.75 37.68-37.68 45.5-45.5 39.375-39.375c6.398-6.398 12.96-12.578 19.195-19.195l.273-.273H496.51l43.312 43.312v-23.188l-20.125 20.125-48.125 48.125-58.133 58.133-50.258 50.258c-8.148 8.148-16.57 16.078-24.445 24.445l-.328.328h23.188l-20.125-20.125-48.125-48.125-58.133-58.133-50.258-50.258c-8.148-8.149-16.188-16.461-24.445-24.445l-.328-.328c-6.07-6.07-17.227-6.508-23.188 0-6.07 6.562-6.563 16.734-.055 23.242z" />
    <path d="m136.94 98.438 176.64 176.64c8.148 8.148 16.188 16.461 24.445 24.445l.328.328c6.29 6.29 16.898 6.29 23.188 0l20.125-20.125 48.125-48.125 58.133-58.133 50.258-50.258c8.149-8.148 16.461-16.188 24.445-24.445l.328-.328c6.29-6.29 6.29-16.898 0-23.188l-38.008-38.008-5.304-5.305c-6.29-6.289-16.898-6.289-23.188 0L480.76 47.631l-37.68 37.68-45.61 45.61-39.43 39.43c-6.343 6.343-12.96 12.577-19.085 19.085l-.274.274h23.188l-15.75-15.75a12230.993 12230.993 0 0 0-37.734-37.898L262.83 90.507l-39.539-39.54c-6.398-6.398-12.633-12.905-19.14-19.14l-.274-.274c-6.29-6.289-16.898-6.289-23.188 0l-38.281 38.281-5.414 5.414c-6.125 6.016-6.618 17.172-.055 23.188s16.734 6.508 23.188 0l43.695-43.695h-23.188l15.75 15.75 37.844 37.844 45.555 45.555 39.539 39.54c6.398 6.398 12.633 12.905 19.14 19.14l.274.274c6.29 6.289 16.898 6.289 23.188 0l15.695-15.695 37.68-37.68 45.61-45.61 39.43-39.43c6.343-6.343 12.96-12.577 19.085-19.085l.274-.274H496.51l43.312 43.312V75.194L519.697 95.32l-48.125 48.125-58.133 58.133-50.258 50.258c-8.149 8.149-16.57 16.078-24.445 24.445l-.328.329h23.188l-20.125-20.125-48.125-48.125-58.133-58.133-50.258-50.258c-8.149-8.149-16.188-16.461-24.445-24.445l-.328-.329c-6.07-6.07-17.227-6.507-23.188 0-6.07 6.563-6.563 16.734-.055 23.242z" />
  </svg>
)